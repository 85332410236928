import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL } from "../../redux/Fetch/Api";
import "./SurveyForm.scss";

const SurveyForm = () => {
	const { surveyId } = useParams();
	const [allQuestions, setAllQuestions] = useState([]);
	const [allQuestionsub, setAllQuestionsub] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const subQuestionSchema = Yup.object().shape({
		rating: Yup.number().min(1).max(10).required("Please give some rating for this question."),
		comment: Yup.string().max(300, "Comment cannot be longer than 300 characters").nullable(),
	});

	// Define the main schema
	const validationSchema = Yup.object().shape({
		quationNumber: Yup.array().of(
			Yup.object().shape({
				question: Yup.array().of(subQuestionSchema),
			})
		),
	});
	console.log({ allQuestions });
	const formik = useFormik({
		initialValues: {
			// quationNumber: projectEvaluation,
			quationNumber: allQuestions,
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			const valuesLength = values.quationNumber.length - 1;
			values.quationNumber[valuesLength].question[0].rating = null;
			console.log(values.quationNumber, "kdsahjk akjdnbckjsdbc ");
			setLoading(true);
			try {
				const res = await axios.post(`${BASE_URL}admin/v1/survey/sumbit/${surveyId}`, values.quationNumber);
				toast.success(res?.data?.message);
				setAllQuestionsub(res?.data?.status == 200);
				// setTimeout(() => {
				// 	navigate("/");
				// }, 2000);
				resetForm({ values: { quationNumber: [] } });
			} catch (error) {
				toast.error(error?.response?.data?.message);
			} finally {
				setLoading(false); // Hide loader
			}
		},
	});
	const getAllQuestions = async () => {
		setLoading(true);
		try {
			const res = await axios.get(`${BASE_URL}admin/v1/survey/all-question/${surveyId}`);
			if (Array.isArray(res?.data?.data)) {
				setAllQuestions(res?.data?.data);
			}
		} catch (error) {
			if (error?.response?.status == 400) {
				toast.error(error.response.data.message);
				setErrorMsg(error.response.data.message);
				setTimeout(() => {
					navigate("/");
				}, 200);
			}
		} finally {
			setLoading(false); // Hide loader
		}
	};
	useEffect(() => {
		getAllQuestions();
	}, []);

	const setFieldValue = (selectedValue, index, subIndex, i) => {
		formik.setFieldValue(
			`quationNumber[${index}].question[${subIndex}].rating`,
			selectedValue?.value === "on" && String(i + 1)
		);
		formik.setFieldValue(`quationNumber[5].question[0].rating`, "10");
	};
	const setCommentFieldValue = (selectedValue, index, subIndex) => {
		formik.setFieldValue(`quationNumber[${index}].question[${subIndex}].comment`, selectedValue?.value);
		formik.setFieldValue(`quationNumber[5].question[0].rating`, "10");
	};

	if (loading) {
		return (
			<div className="loadingPos ">
				<Spinner style={{ minWidth: "50px", minHeight: "50px" }} />
			</div>
		);
	}
	if (allQuestionsub) {
		return (
			<div className="formBox d-flex" style={{ minHeight: "100vh" }}>
				<Container>
					<div
						className="formBox"
						style={{
							display: "flex",
							backgroundColor: "rgba(255,255,255,0.2)",
							backdropFilter: "blur(5px)",
							padding: "130px",
							borderRadius: "10px",
						}}
					>
						<h4 className="mb-4">Thanks for Submiting the Form</h4>
					</div>
				</Container>
			</div>
		);
	}
	return (
		<>
			{!allQuestionsub && (
				<>
					<div className="surveyForm">
						<Container>
							{allQuestions != undefined && allQuestions?.length > 0 ? (
								<div className="formBox">
									<div className="formBox_topHeading">
										<p>{errorMsg}</p>
										<h4 className="mb-4">Assessment Form</h4>
									</div>
									<Form onSubmit={formik?.handleSubmit} className="w-100">
										{formik?.values?.quationNumber.map((item, index) => (
											<div className="surveyForm_formInnerBox" key={index}>
												<Form.Label className="firstHeading mb-0">
													<b>{index + 1}. </b>
													{item?.category}
												</Form.Label>
												<ul>
													{item?.question[0]?.question &&
														item?.question?.map((item, subIndex) => (
															<div className="mt-3" key={item?.id}>
																<li className="mb-1 listHeading">{item?.question}</li>
																<ul>
																	{item?.subQuestion1 && <li>{item?.subQuestion1}</li>}
																	{item?.subQuestion2 && <li>{item?.subQuestion2}</li>}
																</ul>
																<div className="radiobox mt-3 mb-3">
																	<Form.Label className="me-2 me-md-3 mb-0 w-auto">Rating</Form.Label>
																	{Array(10)
																		?.fill(1)
																		?.map((_, i) => (
																			<Form.Check
																				key={i}
																				className="form-star mt-0"
																				type="checkbox"
																				name={`quationNumber[${index}].question[${subIndex}].rating`}
																				onBlur={formik?.handleBlur}
																				checked={
																					formik?.values?.quationNumber?.[index]?.question?.[subIndex]?.rating > i
																				}
																				onChange={(e) => setFieldValue(e?.target, index, subIndex, i)}
																			/>
																		))}
																</div>
																{formik?.errors?.quationNumber &&
																formik?.touched?.quationNumber &&
																formik?.touched?.quationNumber[index]?.question[subIndex]?.id &&
																formik?.errors?.quationNumber[index]?.question[subIndex]?.rating ? (
																	<div style={{ marginTop: "0px" }}>
																		<p className="errorMsg mb-1">
																			{formik?.errors?.quationNumber[index]?.question[subIndex]?.rating}
																		</p>
																	</div>
																) : (
																	""
																)}
																<textarea
																	name={`quationNumber[${index}][${subIndex}].comment`}
																	rows={2}
																	onBlur={formik.handleBlur}
																	onChange={(e) => {
																		setCommentFieldValue(e?.target, index, subIndex);
																	}}
																	placeholder="Comment (Optional)"
																/>
																{formik?.errors?.quationNumber &&
																formik?.touched?.quationNumber &&
																formik?.touched?.quationNumber[index]?.question[subIndex]?.id &&
																formik?.errors?.quationNumber[index]?.question[subIndex]?.comment ? (
																	<div style={{ marginTop: "0px" }}>
																		<p className="errorMsg mt-1">
																			{formik?.errors?.quationNumber[index]?.question[subIndex]?.comment}
																		</p>
																	</div>
																) : (
																	""
																)}
															</div>
														))}
												</ul>
												{!item?.question[0]?.question && (
													<div className="mt-3 ms-3">
														<Form.Label className="mb-2 fw-normal">{item?.question[0]?.subQuestion1}</Form.Label>
														<textarea
															name={`quationNumber[${index}].comment`}
															rows={2}
															onBlur={formik.handleBlur}
															onChange={(e) => {
																setCommentFieldValue(e?.target, index, 0);
															}}
															placeholder="Additional Feedback (Optional)"
														/>
													</div>
												)}
											</div>
										))}
										<div className="btn_row">
											<Button variant="primary" title="Submit" type="submit" className="button ">
												{loading ? "Submiting..." : "Submit"}
											</Button>
										</div>
									</Form>
								</div>
							) : (
								<div className="formBox">
									<p className="text-center mb-0 ">{errorMsg}</p>
								</div>
							)}
						</Container>
					</div>
				</>
			)}
		</>
	);
};

export default SurveyForm;
