import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
  return (
    <section className={styles.error}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={styles.error_left}>
              <h1>
                Oops! <span>404</span> <br /> Page Not Found!
              </h1>
              <p>The page you are looking for is not found.</p>
              <div className={styles.error_left_action}></div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ErrorPage;
