import React, { Fragment } from 'react'
import './Range.css'
import { appConst } from '../../constants/texts'
const Range = ({currentStep=1}) => {
  return (
    <Fragment>
      <div className="range-holder">
        <p>
          <span>
            {currentStep}
          </span>
          <span>/</span>
          <span>
            2
          </span>
        </p> 
      </div>
      {/* <section className="transparent desktop">
        <div className="steps">
          <div className="step active ">
            <i className="bullet"></i>
            <i className="line"></i>
            <span className="title"> {appConst.id_doc} </span>
          </div>
          <div className="step">
            <i className="bullet">
              <i className="fa"></i>
            </i>
            <i className="line"></i>
            <span className="title"> {appConst.selfie} </span>
          </div>
        </div>
      </section> */}
    </Fragment> 
  )
}

export default Range