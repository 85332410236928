import React from "react";
import styles from "./ErrorPage.module.scss";
import { Col, Container, Row } from "react-bootstrap";

const LinkExpireError = () => {
  return (
    <section className={styles.error}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={styles.error_left}>
              <h1>
                <span>Oops!</span> <br /> This Link has been expired!
              </h1>
              <p>Please contact with Admin.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LinkExpireError;
