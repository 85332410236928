import { storage } from "../../constants/texts";
import {
  SET_IMAGES,
  SET_IMAGE_TYPE,
  SET_IMAGE_SIDE,
  START_LOADING,
  SHOW_CLASS_NAME,
  STOP_LOADING,
  SET_DOC_TYPE,
  SET_COUNTRIES,
  SET_UPLOADED_IMAGES_ID,
  SET_KYC_ID,
  SET_KYC_STEP,
  SELECTED_COUNTRY,
  SELECTED_DOC_DETAILS,
  CHECK_EMAIL,
  VERIFICATION,
  SET_SURVEY_DATA,
} from "../Types/actionTypes";
import { SET_EMAIL } from "../actionTypes/actionTypes";

const initialState = {
  kycStep: null,
  // backend       // 1-> initiated  == 0
  // 2-> document uploaded
  // 3-> selfie uploaded
  // 4-> under progress
  // 5-> KYC accepted
  // 6-> KYC rejected

  // frontend :    // 0 : lets verify // accept terms
  // 1 : kycstepone //doc upload
  // 2 : kycsteptwo //pic click intro
  // 3 : kycstepthree //pic click
  loading: false, // true/false
  imgType: null || parseInt(sessionStorage.getItem(storage?.doctType)), // 1,2,3(passport,driving licsense)
  imgSide: null, // front,back,selfie
  showClssName: false, // true/false
  countries: [], // []
  selected_country: "" || sessionStorage.getItem(storage?.country),
  kycID: null, // number
  uploadedImages: {
    // path from backend
    front: null,
    back: null,
    selfie: null,
  },

  uploadedImagesID: {
    // id from backend
    front: null || parseInt(sessionStorage.getItem(storage?.frontDocId)),
    back: null || parseInt(sessionStorage.getItem(storage?.backDocId)),
    selfie: null || parseInt(sessionStorage.getItem("selfiId")),
  },
  docTypes: null,
  docDetails: null,
  userEmail: "",
  verifyInfo: {},
  detail: {},
  kycId: {},
  survayData: [],
};
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_DOC_DETAILS: {
      return {
        ...state,
        docDetails: action.payload,
      };
    }
    case SET_KYC_STEP: {
      return {
        ...state,
        kycStep: action.payload,
      };
    }
    case SET_UPLOADED_IMAGES_ID: {
      let tempObjs = {
        ...state.uploadedImagesID,
      };
      tempObjs[state.imgSide] = action.payload;
      return {
        ...state,
        uploadedImagesID: tempObjs,
      };
    }
    case SET_KYC_ID: {
      console.log({ action });
      return {
        ...state,
        kycID: action?.payload,
      };
    }
    // case SET_UPDATED_KYC_ID: {
    //     console.log({action})
    //     return {
    //         ...state,
    //         kycId: action?.payload
    //     }
    // }
    case SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    case SELECTED_COUNTRY: {
      return {
        ...state,
        selected_country: action.payload,
      };
    }
    case SHOW_CLASS_NAME: {
      return {
        ...state,
        showClssName: action.payload,
      };
    }
    case START_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_IMAGES: {
      let tempObjs = {
        ...state.uploadedImages,
      };
      tempObjs[state.imgSide] = action.payload;
      return {
        ...state,
        uploadedImages: tempObjs,
      };
    }
    case SET_IMAGE_TYPE:
      return {
        ...state,
        loading: true,
        imgType: action.payload,
      };
    case SET_IMAGE_SIDE:
      return {
        ...state,
        imgSide: action.payload,
      };
    case SET_DOC_TYPE:
      return {
        ...state,
        docTypes: action.payload,
      };
    case CHECK_EMAIL: {
      return {
        ...state,
        userEmail: action.payload,
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        detail: action.payload,
      };
    }
    case VERIFICATION: {
      return {
        ...state,
        verifyInfo: action.payload,
      };
    }
    case SET_SURVEY_DATA: {
      console.log(action, "asd");
      return {
        ...state,
        survayData: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default commonReducer;
