import React from 'react'
import './UnorderedList.css'
const UnorderedList = (props) => {
  const {
    list = [],
  } = { ...props };
  return (
    <ul>
      {
        list.map(item => {
          return (
            <li>
              {item}
            </li>
          )
        })
      }
    </ul>
  )
}
export default UnorderedList
