/*

  Page name:  Let's get you verified

*/
import './LetsVerify.css'
import React, { useState } from 'react'
import { Card } from '../../hocs/index'
import { appConst } from '../../constants/texts'
import KycNextButton from '../kycNextButton/KycNextButton'
import { useDispatch, useSelector } from 'react-redux'
import { sendId, updateId } from '../../redux/actions/Actions'
import {v4 as uuidv4} from 'uuid';
import socket from '../../socket'
import { useParams } from 'react-router-dom'

const LetsVerify = (props) => {
  const {
    handleClick = () => { },

  } = { ...props };
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { kycId } = {
    ...state,
  };
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  let uid = `user-id-${uuidv4()}` ;
  const params=useParams()

  const id=params?.id
console.log({state})
  const _handleClick = async()=> {
    setLoading(true);
   const res=  await dispatch(sendId({userId:uid,id:params?.id})); 
   if(res){
    console.log(res,"intial resultt")
    setLoading(false);

  
   }
    handleClick(1);
    socket.on('connect', () => {
      if(sessionStorage.getItem('userId'))
      socket.emit('kyc_id_data', sessionStorage.getItem('userId') );
    });
  }  




  
  return (
    <Card
      bodyCls='verify-card'
    >
      <div className="verify-body">
        {/* header */}
        <div className="verify-header">
          <h2 className="verify-title">
            {appConst.lets_get_verified}
          </h2>
          <p>
            {appConst.verify_desc_1}
          </p>
          <p>
            {appConst.verify_desc_2}
          </p>
        </div>
        {/* terms and condition */}
        <div className="confirm-consent-body">
          {/* checkbox */}
          <input type="checkbox" name="consent" className="consent"
            value={checked}
            onChange={() => {
              setChecked(prev => !prev)
            }}
          />
          {/* terms */}
          <span>
            <p>
              {appConst.i_confirm_1}
              <a href="https://sumsub.com/privacy-notice-service/" rel="noopener" target="_blank">
                {appConst.i_confirm_2}
              </a>
              {appConst.i_confirm_3}
              <a href="" rel="noopener" target="_blank">
                {appConst.i_confirm_4}
              </a>.
            </p>
          </span>
        </div>
        {/* button */}
        <div className={`verify-button-holder ${checked ? '' : 'displayNone'}`}>
          <KycNextButton handleClick={() =>_handleClick()} />
        </div>
      </div>
    </Card>
  )
}

export default LetsVerify
