import React, { Fragment } from "react";
import { Button } from "../../../elements";
import "./ImageMode.css";
import {
  sendKycDocsSelfi,
  setImageSide,
  setImages,
} from "../../../redux/actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../../constants/texts";

const ImageMode = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { setIsLoader = () => {} } = { ...props };

  const { uploadedImagesID } = {
    ...state,
  };
  console.log({props,state})
  const kycid = sessionStorage.getItem(storage?.kycId);
  const callbackSelfie=({result})=>{
    console.log({result})
    if (result.status === 200) {
      setIsLoader(false);

      sessionStorage.setItem("step_kyc", 4);

      props.handleClick(4);
    }
  };
  const callback = ({ result }) => {
    console.log({result})
    
    dispatch(
      sendKycDocsSelfi(
        {
          kycId: kycid,
          selfieId: result,
        },callbackSelfie))
      }
      
   

  const SelfiApiFunc = () => {
    console.log(uploadedImagesID?.selfie,"uploadedImagesID?.selfie")
    if(uploadedImagesID?.selfie){
    dispatch(
      sendKycDocsSelfi(
        {
          kycId: kycid,
          selfieId: uploadedImagesID?.selfie,
        },
        callbackSelfie
      )
    );
      }
  };

  const { _handleClick, imgSrc } = { ...props };

  return (
    <Fragment>
      {/* camera and image */}
      <Fragment>
        {/* after click image */}
        <img src={imgSrc} alt="captured-image" height="400px" width="640px" />
      </Fragment>

      {/* instruction and button */}
      <div className="container video">
        <Button
          clsname={"capture-button"}
          title={"Re-capture"}
          onClick={() => {
            dispatch(setImageSide("selfie"));
            dispatch(setImages(null));
            _handleClick();
            sessionStorage.removeItem("imageURL");
          }}
        />
        <Button
          clsname={"capture-button"}
          title={"Submit"}
          disabled={!uploadedImagesID?.selfie}
          onClick={() => SelfiApiFunc()}
        />
      </div>
    </Fragment>
  );
};

export default ImageMode;
