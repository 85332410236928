// LOGIN --->
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const GET_IMAGE = "GET_IMAGE";
export const SET_IMAGES = "SET_IMAGES";
export const SET_IMAGE_TYPE = "SET_IMAGE_TYPE";
export const SET_IMAGE_SIDE = "SET_IMAGE_SIDE";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SHOW_CLASS_NAME = "SHOW_CLASS_NAME";
export const GET_DOC_TYPE = "GET_DOC_TYPE";
export const SET_DOC_TYPE = "SET_DOC_TYPE";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SEND_ID = "SEND_ID";
export const SEND_KYC_DOCS = "SEND_KYC_DOCS";
export const SET_USER_ID = "SET_USER_ID";
export const SET_UPLOADED_IMAGES_ID = "SET_UPLOADED_IMAGES_ID";
export const SET_KYC_ID = "SET_KYC_ID";
export const GET_KYC_STATUS = "GET_KYC_STATUS";
export const SET_KYC_STEP = "SET_KYC_STEP";
export const SEND_KYC_DOCS_SELFI = "SEND_KYC_DOCS_SELFI";
export const SELECTED_COUNTRY = "SELECTED_COUNTRY";
export const CHECK_LIVENESS = "CHECK_LIVENESS";
export const SELECTED_DOC_DETAILS = "SELECTED_DOC_DETAILS";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const VERIFICATION = "VERIFICATION";
export const GET_SURVEY_DATA = "GET_SURVEY_DATA";
export const SET_SURVEY_DATA = "SET_SURVEY_DATA";
export const SEND_SURVEY_DATA = "SEND_SURVEY_DATA";
