import './KycStatus.css';
import Status from '../status/Status';
import React, { useEffect, useState } from 'react';
import { KycStepOne } from '../kycStepOne';
import { appConst } from '../../constants/texts';
import LetsVerify from '../letsVerify/LetsVerify';
import KycStepTwo from '../kycStepTwo/KycStepTwo';
import { KycStepThree } from '../kycStepThree/index';
import { getKYCStatus,setKYCStep } from '../../redux/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../socket';


const KycStatus = ({onHide}) => {
  const [step, setStep] = useState(0 || (sessionStorage.getItem('kycStatus')!=null && sessionStorage.getItem('kycStatus')));

  const dispatch = useDispatch();
  const state = useSelector(state => state.common);
  const [isLoader, setIsLoader] = useState(false);



  console.log(isLoader,'isLoader',step);
  let {
    kycStep
  } = { ...state };
  // cases for step and pages
  // null : status  // kyccompleted or not
  // 0 : lets verify // accept terms
  // 1 : kycstepone //doc upload
  // 2 : kycsteptwo //pic click intro
  // 3 : kycstepthree //pic click 
  const _handleNextButton = (num) => {
    if (sessionStorage.getItem('kycid')) {
      setStep(kycStep);
    }
    else{
      setStep(num);
    }
  };
  const handleNextButton = (num) => {
    console.log({num})
    let number=Number(num)
    
    if ( number!= 4){
      setStep(number);
    }
    else{
      setStep(null);
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem('kycid')) {
      dispatch(getKYCStatus(sessionStorage.getItem('kycid')));
    }
  }, [])

  useEffect(() => {
    socket.on('kycStatus', async (data) => {
      sessionStorage.setItem('kycStatus',data);
      dispatch(setKYCStep(data));
      setTimeout(() => {
        if(sessionStorage.getItem('kycStatus') && sessionStorage.getItem('kycStatus')>=9 )
        {
          sessionStorage.clear();
          setIsLoader(false)
          // setStep(0);
          socket.disconnect();
        }
      }, 1000);
    });
  }, []) 



  return (
    <>
      {
        sessionStorage.getItem('kycStatus') != 7 && step != null
          ?
          step == 0
            ?
            <LetsVerify
              handleClick={handleNextButton}
            />
            :
            step == 1
              ?
              <KycStepOne
                handleClick={handleNextButton}
              />
              :
              step == 2
                ?
                <KycStepTwo
                  handleClick={handleNextButton}
                />
                :
                <KycStepThree
                  handleClick={handleNextButton}
                  setIsLoader={setIsLoader}
                />
          :
          <Status
            kycStep={sessionStorage.getItem('step_kyc')}
            step={step}
            handleClick={_handleNextButton}
            isLoader={isLoader}
            onHide={onHide}
          />
       } 

    </>
  )
}

export default KycStatus