import React from 'react'
import './Card.css'
const Card = (props) => {
    const {
      bodyCls='',
      children
    }= {...props}
  return (
    <div className={`card-body ${bodyCls}`}>
        {children}
    </div>
  )
}
export default Card ;
