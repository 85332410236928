import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
const UseFaceDetection = () => {
  const width = 500;
  const height = 500;

  const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection({
    faceDetectionOptions: {
      model: 'short',
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),
    camera: ({ mediaSrc, onFrame }) =>
  new Camera(mediaSrc, {
        onFrame,
        width,
        height,
      }),  
  });
  return {
    webcamRef, boundingBox, isLoading, detected, facesDetected //, setReinitialiseCamera
  }
}

export default UseFaceDetection