import { take, takeEvery, takeLatest } from "redux-saga/effects";
import * as types from "../actionTypes/actionTypes";
import {
  checkEmail,
  getCountriesSaga,
  getDoclistSaga,
  getImageSaga,
  getKYCDataSaga,
  sendDocDataSaga,
  sendDocDataSagaSelfi,
  sendIDSaga,
  updateIDSaga,
  uploadImageSaga,
  verifyInfo,
  getSurveyDataSaga,
  sendSurveyData,
} from "./CommonSaga";
export function* mySaga() {
  yield takeLatest(types.UPLOAD_IMAGE, uploadImageSaga);
  yield takeLatest(types.GET_IMAGE, getImageSaga);
  yield takeLatest(types.GET_DOC_TYPE, getDoclistSaga);
  yield takeLatest(types.GET_COUNTRIES, getCountriesSaga);
  yield takeLatest(types.SEND_ID, sendIDSaga);
  yield takeLatest(types.UPDATE_ID, updateIDSaga);

  yield takeLatest(types.SEND_KYC_DOCS, sendDocDataSaga);
  yield takeLatest(types.SEND_KYC_DOCS_SELFI, sendDocDataSagaSelfi);
  yield takeLatest(types.GET_KYC_STATUS, getKYCDataSaga);
  yield takeLatest(types.CHECK_EMAIL, checkEmail);
  yield takeLatest(types.VERIFICATION, verifyInfo);
  yield takeLatest(types.GET_SURVEY_DATA, getSurveyDataSaga);
  yield takeLatest(types.SEND_SURVEY_DATA, sendSurveyData);
}
