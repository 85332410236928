export const appConst = {
	BASE_URL: "https://api.antiersolutions.com/",
	// BASE_URL: "https://stage-api.antiers.world/",
	// PLACEHOLDERS
	ENTER_TEXT: "Enter your text...",
	// WARNINGS
	REMOVE_IMAGE: "If you want to change the document type or country, remove the images that you have already uploaded.",
	// HEADINGS
	kyc: "kyc",
	not_submitted_kyc: "Your KYC is NOT SUBMITTED", // initial case when the form is not visited yet
	pending_kyc: "Your KYC is PENDING", // secondary case when user either visited the form or partially filled the form
	lets_get_verified: "Let's get you verified",
	verify_desc_1: "Before you start, prepare your identity document and make sure it is valid.",
	verify_desc_2: "We also require you to agree to the processing of your personal data:",
	i_confirm_1: "I confirm that I have read the ",
	i_confirm_2: "Privacy Notice ",
	i_confirm_3: "and give my consent to the processing of my personal data, including biometrics, as described in this ",
	i_confirm_4: "Consent",
	id_doc: "identity document",
	selfie: "Selfie",
	choose_country: "Select issuing country",
	choose_doc: "Choose your document type",
	id_card: "ID card",
	passport: "Passport",
	residense_pmt: "Residence permit",
	driver_lic: "Driver's license",
	select_option: "Select an option",
	kyc_submitted: "Your KYC is SUBMITTED.",

	application_data: "Application data",
	id_doc: "Identity document",

	kyc_inReview: "Your KYC is IN-REVIEW.",
	kyc_approved: "Your KYC is APPROVED.",
	kyc_rejected: "Your KYC is REJECTED.",
	thank_you: "Thank you",
	data_processing: "We are processing your data.",
	will_update_automatically: "Your verification status will update automatically.",

	header3: "Take a photo of your ID card.The photo should be:", //id_header
	header1: "Take a photo of your passport.The photo should be:", // pass_header
	header2: "Take a photo of your driver's license.The photo should be:", //driver_header
	// header3: 'Take a photo of your Residence permit.The photo should be:',//res_header while using it make header3 as header4 for driver's license

	bright: "bright and clear,",
	visible: "all corners of the document should be visible.",

	upload_doc: "Upload document",
	upload_the: "Upload the ",
	front: "front ",
	back: "back ",
	ur_doc: "of your document",
	continue_on_phone: "Continue on phone",
	i_ready: "I'm ready",

	selfie_desc: "Face the camera. Ensure your face is within the frame.",
	position_face: "Position your face to fit the frame",
	upload_doc: "Upload document",
	// BUTTON TITLES
	submit: "Submit",
	next: "Next",

	// COLORS
	primaryBlue: "#050823",
	secondaryBlue: "#070B2F",
	ternaryBlue: "#131841",
	buttonBlue: "#3486FF",

	white: "#ffffff",
	black: "#000000",
};

export const documentTypes = [
	{
		name: appConst.id_card,
		heading: appConst.id_header,
	},
	{
		name: appConst.passport,
		heading: appConst.pass_header,
	},
	{
		name: appConst.driver_lic,
		heading: appConst.driver_header,
	},
];

export const photoConditions = [appConst.bright, appConst.visible];
export let storage = {
	frontDocId: "fid",
	backDocId: "bid",
	kycId: "kid",
	userId: "uid",
	doctType: "dt",
	kycStatus: "ks",
	selfieDone: "sd",
	country: "cy",
};

export const docUploadImages = {
	img1: [
		"https://static.sumsub.com/idensic/img/i_idcard_correct.e6412a46.png",
		"https://static.sumsub.com/idensic/img/i_idcard_incorrect_1.42c3b612.png",
		"https://static.sumsub.com/idensic/img/i_idcard_incorrect_2.c13c1bd7.png",
	],
	img2: [
		"https://static.sumsub.com/idensic/img/i_passport_correct.e3e8efdd.png",
		"https://static.sumsub.com/idensic/img/i_passport_incorrect_1.293168d3.png",
		"https://static.sumsub.com/idensic/img/i_passport_incorrect_2.02c0839f.png",
	], //passport img
};

export let URLS = {
	SOCKET: {
		KYC_ID_DATA: "http://10.1.5.78:3031 ",
	},
};
