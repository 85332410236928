import React, { Fragment, useEffect, useState } from "react";
import { appConst } from "../../../constants/texts";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../elements";
import Webcam from "react-webcam";
import UseFaceDetection from "../../../utils/customHooks/useFaceDetection";
import "./VideoMode.css";
import { useDispatch } from "react-redux";
import { setImageSide, setImages, uploadImage } from "../../../redux/actions/Actions";

const VideoMode = (props) => {
  const dispatch = useDispatch();

  const { _handleClick = () => {}, capture = () => {} ,cameraEnabled} = { ...props };
  const { webcamRef, boundingBox, detected,facesDetected } = UseFaceDetection();
  
  const handleCapture = async(e) => {
    const imageSrc = webcamRef.current.getScreenshot();

    try {
      dispatch(uploadImage({ body: { photo: imageSrc, type: "base64" } }));
      dispatch(setImageSide('selfie'));
      // capture(true, detected, webcamRef);
      showImageSelfi(imageSrc);
      // setCameraEnabled(false)
      // const stream = webcamRef.current.video.srcObject;

      // const tracks = stream.getTracks();
      // tracks.forEach(track => track.stop());
      // console.log({tracks})
    } catch (error) {
      console.log(error);
    }
  };

  const showImageSelfi = (imageSrc) => {
    // Sample base64 encoded image data
    const base64ImageData = imageSrc; // Replace '....' with your actual base64 data

    // Convert base64 to Blob
    const blob = dataURItoBlob(base64ImageData);

    // Create an Object URL from the Blob
    const imageUrl = URL.createObjectURL(blob);

    // Function to convert data URI to Blob
    function dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
    // dispatch(setImages(imageUrl));
      sessionStorage.setItem('imageURL',imageUrl)
  };
  return (
    <Fragment>
      <Fragment>
        {/* camera */}
        <FontAwesomeIcon
          size="2xl"
          icon={faXmark}
          className="close"
          style={{ color: "#ffffff" }}
          onClick={_handleClick}
        />
        {boundingBox.map((box, index) => (
          <div
            key={`${index + 1}`}
            style={{
              border: "4px solid red",
              position: "absolute",
              top: `${box.yCenter * 35}%`,
              left: `${box.xCenter * 100}%`,
              width: `${box.width * 100}%`,
              height: `${box.height * 100}%`,
              width: "30%",
              overflow: "hidden",
            }}
          />
        ))}
        {cameraEnabled &&
        <Webcam
          height={400}
          width={628}
          ref={webcamRef}
          imageSmoothing={true}
          screenshotFormat={"image/png"}
          id="video"
        />
}
      </Fragment>

      {/* instruction and button */}
      <div className="container">
        <p>{appConst.position_face}</p>
        <Button
          clsname={"capture-button"}
          title={"Capture photo"}
          isDisabled={!detected}
          onClick={() => {
            // capture(true, detected, webcamRef)

            handleCapture();
          }}
        />
      </div>
    </Fragment>
  );
};

export default VideoMode;
