import Fetch from "../Fetch/Api";
import { put, call, delay, select } from "redux-saga/effects";
import {
  getImage,
  setImages,
  startLoading,
  stopLoading,
  setShowClassName,
  setDocTypes,
  setCountries,
  setUploadedImagesId,
  setKYCID,
  sendKycDocs,
  setKYCStep,
  setImageSide,
  getKYCStatus,
  verificationAction,
  checkEmailAction,
  setEmail,
  setUpdatedKYCID,
  updateId,
  setSurveyData,
} from "../actions/Actions";
import { startSocket } from "../../socket";
import { useParams } from "react-router-dom";
// const params=useParams()
export function* uploadImageSaga({ payload: { body } = {} }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.uploadImageApi, body);
    if (result.status === 1) {
      let { message, imageId } = { ...result?.result };
      console.log(result.result, "rety");
      yield put(getImage(imageId));
    } else {
      console.log(result?.result);
    }
  } catch (error) {
    console.log(error);
    yield put(setShowClassName(true));
    yield put(stopLoading());
  } finally {
    yield put(stopLoading());
  }
}
export function* getImageSaga({ payload, callback }) {
  try {
    const result = yield call(Fetch.getImageApi, payload);
    if (result.status === 1) {
      let { message, imagePath } = { ...result?.result };
      const state = yield select((state) => state.common);
      let { imgType, kycID, uploadedImagesID, imgSide, uploadedImages } = {
        ...state,
      };
      console.log(payload, "payloadvv");

      yield put(setUploadedImagesId(payload));

      yield put(setImages(imagePath));
      if (imgSide == "selfie") {
        callback(payload);
        // yield put(sendKycDocs({
        //   frontId: uploadedImagesID.front,
        //   backId: uploadedImagesID.back,
        //   docType: imgType || sessionStorage.getItem('docType'),
        //   selfieId: payload,
        //   kycId: sessionStorage.getItem('kycid')
        // }))
        sessionStorage.setItem("kycStatus", 3);
      }
    } else {
      console.log(result?.result);
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}
export function* getDoclistSaga() {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getDocTypeApi);
    if (result.status === 1) {
      let { data } = { ...result?.result };
      yield put(setDocTypes(data));
    } else {
      console.log(result?.result);
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}
export function* getCountriesSaga() {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getCountriesApi);
    if (result.status === 1) {
      console.log(result?.result, "egdfhgdfh");

      yield put(setCountries(result?.result?.data));
    } else {
      console.log(result);
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}
export function* sendIDSaga(payload) {
  console.log(payload);
  try {
    const result = yield call(Fetch.sendIdApi, payload?.payload?.userId);
    console.log(result, "RESSSSSSS");
    if (result?.status === 1) {
      const { kycId } = {
        ...result?.result,
      };
      console.log({ kycId });

      const res = yield put(
        updateId({ userId: payload?.payload.id?.split("&")[0], kycId: kycId })
      );
      yield put(setKYCID(kycId));
      if (res) {
        sessionStorage.setItem("kid", kycId);
        yield put(getKYCStatus(kycId));

        sessionStorage.setItem("userId", payload);
        startSocket(payload);
      }
    } else {
      console.log(result?.result);
    }
    return result;
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}

export function* updateIDSaga(payload) {
  console.log({ payload });
  try {
    const result = yield call(Fetch.updateIdApi, payload?.payload);
    if (result.status === 1) {
      console.log({ result });
      // const { kycId } = {
      //   ...result?.result,
      // };
      // yield put(setKYCID(kycId));
      // sessionStorage.setItem("kid", kycId);
      // yield put(getKYCStatus(kycId));
      // sessionStorage.setItem("userId", payload);
      // startSocket(payload);
    } else {
      console.log(result?.result);
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}
export function* sendDocDataSaga({ payload, callback }) {
  try {
    const result = yield call(Fetch.sendDocDataApi, payload);
    callback(result);
  } catch (error) {
    console.log("error", error);
  } finally {
    yield put(stopLoading());
  }
}

export function* sendDocDataSagaSelfi({ payload, callback }) {
  console.log({ payload });
  try {
    const result = yield call(Fetch.sendDocDataApiSelfi, payload);
    callback(result);
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}

export function* getKYCDataSaga({ payload }) {
  try {
    const result = yield call(Fetch.getKYCStatusApi, payload);

    if (result.status === 1) {
      const { data } = {
        ...result?.result,
      };
      console.log({ data });
      yield put(setKYCStep(data?.status));
      if (data?.docType) sessionStorage.setItem("docType", data?.docType);
      sessionStorage.setItem("kycStatus", data?.status);
      if (data?.frontId) {
        yield put(setImageSide("front"));
        sessionStorage.setItem("frontId", data?.frontId);
        if (data?.status < 3) yield put(getImage(data?.frontId));
        yield delay(100);
      }

      if (data?.backId) {
        yield put(setImageSide("back"));
        sessionStorage.setItem("backId", data?.backId);
        if (data?.status < 3) yield put(getImage(data?.backId));
      }

      if (data?.selfieId) {
        yield put(setImageSide("selfie"));
        sessionStorage.setItem("selfieId", data?.selfieId);
        if (data?.status < 3) yield put(getImage(data?.selfieId));
      }
    } else {
      console.log(result?.result);
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}

export function* verifyInfo({ payload }) {
  console.log({ payload });
  try {
    if (payload?.id) {
      const result = yield call(
        Fetch.verifyDetails,
        payload?.data,
        payload?.id
      );
      if (result) {
        yield put(verificationAction(result?.data));
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}

export function* checkEmail({ payload }) {
  try {
    yield put(startLoading());

    if (payload) {
      const result = yield call(Fetch.checkEmailApi, payload);
      if (result) {
        console.log(result?.result, "result?.result?.data");
        yield put(setEmail(result?.result));
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}

export function* getSurveyDataSaga() {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getSurveyData);
    console.log({ result });
    const surveyData = result?.result?.data;
    if (Array.isArray(surveyData)) {
      // Add additional key-value pair to each object in the array
      const modifiedSurveyData = surveyData.map((item) => ({
        ...item,
        answer: 0, // Replace with actual key and value
      }));

      // Dispatch action with modified data
      yield put(setSurveyData(modifiedSurveyData));
    } else {
      // Handle case where surveyData is not an array if necessary
      yield put(setSurveyData(surveyData));
    }

    // if (result?.status === 1) {
    // yield put(setSurveyData(result?.result?.data));
    // } else {
    //   console.log(result?.result);
    // }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}

export function* sendSurveyData({ payload, callback }) {
  console.log(payload, " asdfasdfsadf");
  try {
    const result = yield call(Fetch.sendSurveyDataApi, payload);
    callback(result);
  } catch (error) {
    console.log(error);
  } finally {
    yield put(stopLoading());
  }
}
