import React, { Fragment, useEffect } from "react";
import { appConst } from "../../../../../constants/texts";
import { faCloudArrowUp, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useImageUpload } from "../../../../../utils/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../../../hocs";
import { Spinner } from "../../../../../elements";
import { setShowClassName } from "../../../../../redux/actions/Actions";

const ImageUpload = () => {
  const { values, setUploadedImage } = useImageUpload();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  let { front, back, showInputFront, showInputBack } = { ...values };

  let { showClssName, uploadedImages } = state;
  console.log({front})
  return (
    <div className="upload-section">
      {/* front side */}
      <Fragment>
        <label htmlFor={"doc-upload-front"}>
          <Card
            bodyCls={
              uploadedImages.front
                ? "upload-card"
                : showClssName
                ? "upload-card error"
                : "upload-card"
            }>
            {
              // state.uploadedImages.front
              front ? (
                <div className="uploaded-img-container">
                  {state.loading ? (
                    state.uploadedImages.front ? (
                      <div
                        className="trash-container"
                        onClick={() => {
                          setUploadedImage(false, "front");
                        }}>
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          size="2xl"
                          style={{
                            color: "#ffffff", //margin: '8px 0 0 11px',
                            position: "relative",
                          }}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <div
                      className="trash-container"
                      onClick={() => {
                        setUploadedImage(false, "front");
                      }}>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        size="2xl"
                        style={{
                          color: "#ffffff",
                          //margin: '8px 0 0 11px',
                          position: "relative",
                        }}
                      />
                    </div>
                  )}
                  <img
                    src={state.uploadedImages.front?.image_path}
                    alt="uploadedImage"
                    width={"100%"}
                  />
                </div>
              ) : (
                <Fragment>
                  <FontAwesomeIcon
                    icon={faCloudArrowUp}
                    size={"2xl"}
                    style={{ color: "#CACCE3" }}
                  />
                  <h4 id={"front"}>
                    <Fragment>
                      {appConst.upload_the}
                      <span>{appConst.front}</span>
                      {appConst.ur_doc}
                      <span> *</span>
                    </Fragment>
                  </h4>
                </Fragment>
              )
            }
          </Card>
        </label>
        {showInputFront ? (
          <input
            type="file"
            name="doc-upload-front"
            id="doc-upload-front"
            accept=".png, .jpg, .jpeg, .pdf, .docx"
            onChange={(e) => {
              setUploadedImage(e, "front");
            }}
            disabled={state?.loading}

          />
        ) : null}
      </Fragment>
      {/* back side */}
      <Fragment>
        <label htmlFor="doc-upload-back" className="doc-upload-back">
          <Card
            // bodyCls={showClssName ? 'upload-card error':'upload-card'}
            bodyCls={
              uploadedImages.back
                ? "upload-card"
                : showClssName
                ? "upload-card error"
                : "upload-card"
            }>
            {
              // state.uploadedImages.back
              back ? (
                <div className="uploaded-img-container">
                  {state.loading ? (
                    state.uploadedImages.back ? (
                      <div
                        className="trash-container"
                        onClick={() => {
                          setUploadedImage(false, "back");
                          dispatch(setShowClassName(true));
                        }}>
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          size="2xl"
                          style={{ color: "#ffffff" }}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )
                  ) : (
                    <div
                      className="trash-container"
                      onClick={() => {
                        setUploadedImage(false, "back");
                        dispatch(setShowClassName(true));
                      }}>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        size="2xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  )}
                  <img
                    src={state.uploadedImages.back?.image_path}
                    alt="uploadedImage"
                    width={"100%"}
                  />
                </div>
              ) : (
                <Fragment>
                  <FontAwesomeIcon
                    icon={faCloudArrowUp}
                    size="2xl"
                    style={{ color: "#CACCE3" }}
                  />
                  <h4 id={"back"}>
                    <Fragment>
                      {appConst.upload_the}
                      <span>{appConst.back}</span>
                      {appConst.ur_doc}
                      <span> *</span>
                    </Fragment>
                  </h4>
                </Fragment>
              )
            }
          </Card>
        </label>
        {showInputBack ? (
          <input
            type="file"
            name="doc-upload-back"
            id="doc-upload-back"
            accept=".png, .jpg, .jpeg, .pdf, .docx"
            onChange={(e) => setUploadedImage(e, "back")}
            disabled={state?.loading}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default ImageUpload;
