import { Modal } from "react-bootstrap";
import KycStatus from "../components/kycStatus/KycStatus";
import { useParams } from "react-router-dom";

function KycModal({ show, onHide }) {
  const params = useParams();

  return (
    <Modal
      show={show}
      onHide={onHide}
      // className={`commonModal ${className}`}
      centered
      size="xl"
      // backdrop
      // keyboard={keyboard}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verification Identity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <KycStatus onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
}

export default KycModal;
