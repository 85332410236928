import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./MobileInput.scss";

const MobileInput = ({ id, name, onChange, value,specialLabel ,disabled,isValid}) => {
  return (
    <PhoneInput
      id={id}
      name={name}
      containerClass="mobile_input"
      buttonClass="mobile_input_flagBtn"
      country={"in"}
      enableSearch={true}
      value={value}
      onChange={onChange}
      placeholder=""
      specialLabel={specialLabel}
      disabled={disabled}
        isValid={isValid}
        countryCodeEditable={false}

        
    />
  );
};

export default MobileInput;
