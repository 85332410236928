import { io } from 'socket.io-client';
import { URLS } from '../constants/texts';

const socket = io.connect(URLS.SOCKET.KYC_ID_DATA, {
  reconnection: true,
  reconnectionDelay: 1000,
  // reconnectionDelayMax: 5000,
  // reconnectionAttempts: 99999,
  transports: ['websocket'],
});
 
socket.on('connect', () => {
  console.log('connected to server',socket.id);
  if(sessionStorage.getItem('userId'))
  socket.emit('kyc_id_data', sessionStorage.getItem('userId') );
});

socket.on('connect_error', (err) => {
  console.log(`connect_error due to ${err.message}`);
});
 
socket.on('disconnect', () => {
  console.log('disconnected to server',socket.id);
}); 

export default socket;

export const startSocket = (uid) => {
  if (socket.connected && socket.id) {
      socket.emit('kyc_id_data', sessionStorage.getItem('userId') );
  } 
}; 