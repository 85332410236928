import "./DocType.css";
import  DocUpload  from "../docUpload/main/DocUpload";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { RadioButton } from "../../../../elements";
import { appConst } from "../../../../constants/texts";
import {
  getDocTypes,
  setImageType,
  setShowClassName,
  stopLoading,
} from "../../../../redux/actions/Actions";
// import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../../loader/loader";
// import { SmallLoader } from "../../../smallLoader/SmallLoader";

const DocType = () => {
  const [showDocUpload, setShowDocUpload] = useState(false);

  const state = useSelector((state) => state.common);

  const { showClssName, imgType, docTypes ,selected_country,loading} = { ...state };
  console.log("asdfadfasdfasdf", imgType);

  const dispatch = useDispatch();
  let docRef = useRef(null);

  const handleChange = (e) => {
    dispatch(setImageType(e));

    setShowDocUpload(e);
    docRef.current = e;
    dispatch(setShowClassName(false));
    dispatch(stopLoading());
  };

  useEffect(() => {
    dispatch(getDocTypes());
    if (imgType) {
      setShowDocUpload(true);
    }
  }, []);
console.log({selected_country,docTypes})
  return (
    <div className="doc-type">
      {/* { loading ? <Loader /> : null} */}
      {/********************* radio button *********************************/}
      {loading && docTypes == null && <Loader />}
      <div>
        {Array.isArray(docTypes) && docTypes.length > 0 ? (
          <>
            <h3>
              <span className="choose-doc">{appConst.choose_doc}</span>
            </h3>
            <ul className="selectDocType">
              {/* selected_country!=="India" ? (docTypes?.slice(0,2)): */}
              {(docTypes)?.map((item, index) => {
                return (
                  <li>
                    <RadioButton
                      index={item.id}
                      id={item?.document_id}
                      checked={
                        imgType ? (imgType === item.id ? true : false) : false
                      }
                      title={item?.document_name}
                      handleChange={() => handleChange(item.id, item)}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}

        {showClssName && imgType == null ? (
          <p className="error-text">{appConst.select_option}</p>
        ) : null}
      </div>

      {/*********************  doc upload ***********************************/}
      {showDocUpload ? (
        <Fragment>
          <DocUpload uploadType={docRef.current} />
        </Fragment>
      ) : null}
    </div>
  );
};

export default DocType;
