import  { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'; 
import { setImageSide, uploadImage } from '../../redux/actions/Actions';
 
const UseCamera = () => {
  const [imgSrc, setImgSrc] = useState(null); // initialize it 
  const dispatch = useDispatch();
  const capture = useCallback((shouldCapture,isFaceDetected,webcamRef) => {
    if (shouldCapture && isFaceDetected) {
      const imageSrc = webcamRef.current.getScreenshot(); 
      dispatch(setImageSide('selfie')); 
      setImgSrc(imageSrc); 
    } else {
      setImgSrc(null);
    }
  }, []); 
  return  {values:{
    imgSrc
  },
  actions :{
    capture,
    setImgSrc
  }}
}

export default UseCamera