import React from 'react'
import { Button } from '../../elements'
import './KycNextButton.css'
import { appConst } from '../../constants/texts'
const KycNextButton = (props) => {
  const {
    handleClick = () => { },
    title='',
    right = true,
    clsname='',
    left=null,
    
  } = { ...props }


  return (
      <Button  
        title={title || appConst.next} 
        clsname={ `next-button ${clsname}`} 
        isDisabled={clsname == 'button-disable' ? true : false}
        rightChild={right ? <span className="arrow">&gt;</span>: null} 
        leftChild={left ? left: null} 
        onClick={handleClick}
      />
  )
}

export default KycNextButton