import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ClientSide from "../components/ClientSide/ClientSide";
import SurveyForm from "../components/SurveyForm/SurveyForm";
import ErrorPage from "../error/ErrorPage";

const Routers = () => {
	return (
		<Suspense fallback={"Loading...."}>
			<BrowserRouter>
				<Routes>
					<Route path={"/:id"} element={<ClientSide />} />
					<Route path={"/*"} element={<ErrorPage />} />
					<Route path={"/survey/:surveyId"} element={<SurveyForm />} />
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
};
export default Routers;
