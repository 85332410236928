import React, { useEffect, useState } from "react";
import { appConst } from "../../../constants/texts";
import "./Country.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedCountryAction,
} from "../../../redux/actions/Actions";
import { countries } from "./countries";

const Country = () => {
  const dispatch = useDispatch();
  // const countries = useSelector((state) => state.common?.countries);
  const selectedCountry = useSelector(
    (state) => state.common?.selected_country
  );
  const loading = useSelector(
    (state) => state.common?.loading
  );

  // useEffect(() => {
  //   // dispatch(getCountries());
  // }, []);

  // ACTION TO SAVE THE COUNTRY SELECTED THROUGH USER OR DEFAULT
  const saveCountrySeleted = (data) => {
    dispatch(selectedCountryAction(data));
    // setShow(true) 
   };
// console.log({loading,countries})
  return (
    <div className="country">
<>
      <h3 className="title">{appConst.choose_country}</h3>
      <div className="dropdown">
        <select
          placeholder="Select.."
          defaultValue={"Select.."}
          name="drop"
          id="drop"
          onChange={(e) => {
            saveCountrySeleted(e.target.value);
          }}
          value={selectedCountry}
        >
          {countries?.map((item) => {
            return (
              <option value={`${item.name}`}>
                {item?.name}
              </option>
            );
          })}
        </select>
       
      </div>
      </>
        
    </div>
  );
};

export default Country;
