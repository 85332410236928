/*

  Page name:  Selfie (Capture and Submit)

*/
import React, { useEffect, useState } from "react";
import "./KycStepThree.css";
import { Card } from "../../../hocs";
import { useCamera } from "../../../utils/customHooks";
import ImageMode from "../imageMode/ImageMode";
import VideoMode from "../videoMode/VideoMode";
import { useDispatch, useSelector } from "react-redux";
import {
  getImage,
  setUploadedImagesId,
  uploadImage,
} from "../../../redux/actions/Actions";
import UseFaceDetection from "../../../utils/customHooks/useFaceDetection";

const KycStepThree = (props) => {
  const [imagUrl, setImagUrl] = useState("");
  const [cameraEnabled, setCameraEnabled] = useState(true);

  const { handleClick = () => { } } = { ...props };
  const { webcamRef, boundingBox, detected } = UseFaceDetection();
  // let localstream
  // useEffect(async() => {
  //   let vid = document.getElementById("video");
  //   if (navigator.mediaDevices.getUserMedia !== null) {
  //     var options = {
  //       video: true,
  //       audio: true
  //     };
  //     await navigator.getUserMedia(
  //       options,
  //       function (stream) {
  //         vid.srcObject = stream;
  //         localstream = stream;
  //         vid.play();
  //         console.log(stream, "streaming");
  //       },
  //       function (e) {
  //         console.log("background error : " + e.name);
  //       }
  //     );
  //   }
  // });
  // const capOff = () => {
  //   let vid = document?.getElementById("video");
  //   if (vid) {
  //     vid.pause();
  //     vid.src = "";
  //   }
  //   localstream?.getTracks()?.forEach((x) => x.stop());
  //   console.log("all capture devices off");
  // };
  const _handleClick = async (type) => {
    if (type == "close") {
      // await setCameraEnabled(false)
      handleClick(2);
      // capOff()

    }
  };

  const state = useSelector((state) => state.common);
  const { uploadedImagesID } = {
    ...state,
  };

  const { values, actions } = useCamera(); // initialize it
  const dispatch = useDispatch();

  const { imgSrc } = { ...values };
  const { capture } = { ...actions };
  const imageURl = sessionStorage.getItem("imageURL");

  const isStepDone = sessionStorage.getItem("step_kyc");

  useEffect(() => {
    if (imageURl) {
      props.handleClick(isStepDone);
      // props.setIsLoader(true);
    }
  }, [isStepDone]);
  console.log({ props })
  return (
    <Card bodyCls={"video-card"}>
      {imageURl ? (
        // imgSrc  ||  uploadedImages.selfie
        <ImageMode
          capture={(shouldCapture, isDetected, ref) => {
            capture(shouldCapture, isDetected, ref, true);
            dispatch(uploadImage({ body: { photo: imgSrc, type: "base64" } }));
          }}
          handleClick={handleClick}
          imgSrc={imageURl}
          _handleClick={() => {
            _handleClick("close");
            sessionStorage.removeItem("imageURL");
          }}
          setIsLoader={props.setIsLoader}
        />
      ) : (
        <VideoMode
          capture={(shouldCapture, isDetected, ref) => {
            capture(shouldCapture, isDetected, ref, false);
          }}
          setImagUrl={setImagUrl}
          _handleClick={() => _handleClick("close")}
          cameraEnabled={cameraEnabled}
        />
      )}
    </Card>
  );
};

export default KycStepThree;
