/*

  Page name:  Selfie (Permission)

*/
import './KycStepTwo.css'
import React from 'react'
import { Card } from '../../hocs'
import { appConst } from '../../constants/texts'
import KycNextButton from '../kycNextButton/KycNextButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { Range } from '../../elements'


const KycStepTwo = (props) => {




  const {
    handleClick = () => { }
  } = { ...props };
  const _handleClick = (e, type) => {
    if (type == 'back') {
      handleClick(1);
    } else {
      handleClick(3);
    }
  }
  return (
    <Card bodyCls={'step-three-card'} >
      <div style={{margin : '45px 0 0'}}>
        <Range currentStep={2}/>
      </div>
      <div className="selfie-holder">
        {/* Header */}
        <div className="text-holder">
          <div className="title-holder">
            <h2 className="title">
              {appConst.selfie}
            </h2>
          </div>
          <div className="desc-holder">
            <p className="desc">
              {appConst.selfie_desc}
            </p>
          </div>
        </div>
        {/* camera pic static */}
        <div className="camera-holder">
          <div className="camera">
            <FontAwesomeIcon icon={faCamera} size="2xl" style={{ color: "#050823", fontSize: '110px' }} />
          </div>
        </div>
        {/* buttons */}
        <div className="button-holder">
          <div className="prev-button">
            <KycNextButton
              handleClick={(e) => _handleClick(e, 'back')}
              title={appConst.back} right={false} left={<span className="arrow">&larr;</span>} clsname={'back-trans'} />
          </div>
          <div className="ready-button">
            <KycNextButton
              handleClick={(e) => _handleClick(e, 'next')}
              title={appConst.i_ready} right={false} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default KycStepTwo
