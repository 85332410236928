import React from "react";
import "./Button.css";
const Button = (props) => {
  const {
    title = "",
    clsname = "",
    onClick = () => {},
    leftChild = null,
    rightChild = null,
    isDisabled,
    type,
    style,
  } = { ...props };
  return (
    <button
      type={type}
      className={`basic-button ${clsname}`}
      style={style}
      onClick={onClick}
      disabled={isDisabled}
    >
      <span>{leftChild}</span>
      <span>{title}</span>
      <span>{rightChild}</span>
    </button>
  );
};

export default Button;
