import React, { Fragment } from 'react'
import Webcam from 'react-webcam'
import { useCamera, useFaceDetection } from '../../utils/customHooks';
import Button from '../button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { appConst } from '../../constants/texts';

const WebCamera = ({
    handleClick=()=>{}
}) => {
    const { values, actions } = useCamera(); // initialize it 
    const {
        imgSrc,
    } = { ...values }; 

    const {
        capture,
    } = { ...actions }
    const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection();
    return (
        <Fragment>
            <FontAwesomeIcon
                size="2xl" 
                icon={faXmark} 
                className='close' 
                style={{ color: "#ffffff", }} 
                onClick={() => handleClick('close')}
            /> 
            {boundingBox.map((box, index) => (
                <div
                    key={`${index + 1}`}
                    style={{
                        border: '4px solid red',
                        position: 'absolute',
                        top: `${box.yCenter * 35}%`,
                        left: `${box.xCenter * 100}%`,
                        width: `${box.width * 100}%`,
                        height: `${box.height * 100}%`,
                        width: '40%',
                        // zIndex: 1,
                    }}
                />
            ))}
            <Webcam
                height={400}
                width={700}
                ref={webcamRef}
                screenshotFormat={"image/png"}
            />
            <div className="container">
                <p>
                    { appConst.position_face}
                </p>
                <Button
                    clsname={'capture-button'}
                    title={'Capture photo'}
                    onClick={() => {
                            capture(true, detected, webcamRef)
                    }}
                />
            </div>
        </Fragment>
    )
}

export default WebCamera