/*

  Page name:  Thank you

*/
import React, { Fragment, useEffect } from "react";
import { appConst } from "../../constants/texts";
import { Button, RadioButton } from "../../elements";
import "./Status.css";
import { Card } from "../../hocs";
import {
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "../loader/loader";
import { SmallLoader } from "../smallLoader/SmallLoader";
import { useDispatch } from "react-redux";
import { checkEmailAction } from "../../redux/actions/Actions";
import { useParams } from "react-router-dom";
const Status = (props) => {
  const { kycStep, step, handleClick = () => {},onHide } = { ...props };
const dispatch=useDispatch();
const params = useParams();

  useEffect(() => {
    // sessionStorage.setItem("step_kyc",0);
    dispatch(checkEmailAction(params?.id));
    
  }, [kycStep, step]);

  console.log("step.isLoader", step);
const submitKyc=()=>{
  window?.location?.reload()
}
  return (
    <Fragment>
      {/* <div className="kyc-header">
        {appConst.kyc}
      </div> */}
      {/* <div className="kyc-des"> */}
      {/* <h2 className="kyc-des-title">
          {
            (kycStep >= 3 && kycStep <= 6)  
              ?
              'Your KYC is SUBMITTED.'
              :
              appConst.not_submitted_kyc
          }
        </h2> */}
      {/* <Card> */}
      {
        kycStep >= 3 && kycStep <= 6 ? (
          kycStep == 3 || kycStep == 4 ? (
            <>
            <Card>
                        { props.isLoader == true && <Loader />}

              <div className="verify-body">
                <div className="verify-header">
                  <h2 className="verify-title">{appConst.thank_you}</h2>
                  <h2 className="verify-title">{appConst.data_processing}</h2>
                  <p>{appConst.will_update_automatically}</p>
                  <div className="after-kyc-status-holder">
                    <div className="status-holder">
                      <div className="circle-cut">
                        <FontAwesomeIcon icon={faCircleNotch} rotation={180} style={{top:'-1px'}} />
                      </div>
                      <RadioButton
                        title={appConst.application_data}
                        customLabelCls={"status-check"}
                      />
                    </div>
                    <div className="status-holder">
                      <div className="circle-cut">
                        <FontAwesomeIcon icon={faCircleNotch} rotation={180} style={{top:'-1px'}} />
                        {/* { kycStep == 3 || kycStep == 4  && props.isLoader == true && <SmallLoader />} */}
                      </div>
                      <RadioButton
                        title={appConst.id_doc}
                        customLabelCls={"status-check"}
                      />
                    </div>
                    <div className="status-holder">
                      <div className="circle-cut">
                        <FontAwesomeIcon icon={faCircleCheck} size="2xl" style={{ color: "#40ab12", top:'-22px'}} className='tick-check' />
                        {/* { kycStep == 3 || kycStep == 4  && props.isLoader == true && <SmallLoader />} */}
                      </div>
                      <RadioButton
                        title={appConst.selfie}
                        customLabelCls={"status-check"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
                        <Button title={'Ok'} clsname={'kyc-submit'} onClick={submitKyc}/>
                        </>

          ) : (
            <Card>
              <div className="verify-body">
                <div className="verify-header">
                  {kycStep == 6 ? (
                    <Fragment>
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        size="2xl"
                        style={{ color: "#c41603" }}
                        className="tick-check"
                      />
                      <h2 className="verify-title">{appConst.kyc_rejected}</h2>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="2xl"
                        style={{ color: "#40ab12" }}
                        className="tick-check"
                      />
                      <h2 className="verify-title">{appConst.kyc_approved}</h2>
                    </Fragment>
                  )}
                </div>
              </div>
            </Card>
          )
        ) : null
        // <Button title={appConst.submit} clsname={'kyc-button'} onClick={() => handleClick(0)} />
      }
      {/* </Card> */}
      {/* </div> */}
    </Fragment>
  );
};

export default Status;
