/*

  Page name:  Identity Document

  */
import React, { useEffect, useRef, useState } from "react";
import { Range } from "../../../elements/index";
import { Card } from "../../../hocs";
import { appConst, storage } from "../../../constants/texts";
import "./KycStepOne.css";
import KycNextButton from "../../kycNextButton/KycNextButton";
import Country from "../country/Country";
import { DocType } from "../docType";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedCountryAction,
  sendKycDocs,
  setCountries,
  setImageType,
  setShowClassName,
} from "../../../redux/actions/Actions";
import { toast } from "react-toastify";
const KycStepOne = (props) => {
  const { handleClick = () => {} } = {
    ...props,
  };
  const state = useSelector((state) => state.common);
  const { imgType, uploadedImages, uploadedImagesID } = { ...state };
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const docTypeListState = state?.docTypes;
  const docTypeCountry = state?.countries;
  const selectedCountry = state?.selected_country;
  const [docError, setDocError] = useState("");

  /**************** SUBMIT THE KYC FRONT DOC AND BACK DO WITH COUNTRY API RESPONSE ******************/
  const callback = ({ result }) => {
    if (result?.status == 200) {
      setIsLoader(false);
      handleClick(2);
    } else if (result.status == 400) {
      // toast.error(result?.message);
      setDocError(result?.message);
      updateData(result?.data?.Doc);
      updateCountry(result?.data?.country);
      setIsLoader(false);
    }
  };

  /********** FINDING THE RIGHT DOC FROM API RESPONSE AND COMPARE WITH USER SELECTED DOC TYPE AND SELECTING BY DEFAULT TO THE DESIRE DOC TYPE ******************/
  const updateData = (data) => {
    const SelectDoc = docTypeListState.find(
      (item) => item.document_name === data
    );
    dispatch(setImageType(SelectDoc.id));
  };

  /********** FINDING THE RIGHT COUNTRY FROM API RESPONSE AND COMPARE WITH USER SELECTED COUNTRY AND SELECTING BY DEFAULT TO THE DESIRE COUNTRY ******************/
  const updateCountry = (data) => {
    const selectCountry = docTypeCountry.find(
      (item) => item.name.common === data
    );
    dispatch(selectedCountryAction(selectCountry.name.common));
  };

  /********** HERE THIS FUNCTION IS CALLING ONLY ON NEXT BUTTION CLICK ******************/
  const handleNext = () => {
    // setIsLoader(true);
    if (imgType && uploadedImages.front && uploadedImages.back) {
      setIsLoader(true);
      dispatch(
        sendKycDocs(
          {
            frontId: uploadedImagesID.front,
            backId: uploadedImagesID.back,
            docType: imgType || sessionStorage.getItem("docType"),
            kycId: sessionStorage.getItem(storage.kycId),
            country: selectedCountry ? selectedCountry : "Belize",
          },
          callback
        )
      );
    } else {
      dispatch(setShowClassName(true));
    }
  };
  /*********************************** END ***********************************************/

  useEffect(() => {
    setTimeout(() => {
      setDocError("");
    }, [2000]);
  }, [docError]);

  return (
    <div className="stepone-body">
      <Card bodyCls={"stepone-body-card"}>
        <Range currentStep={1} />
        <p className="title">{appConst.id_doc}</p>
        <Country />
        <DocType isLoader={isLoader} />
        {docError && <p style={{ color: "red" }}>{`* ${docError}`}</p>}
        <div className="center-button">
          <KycNextButton handleClick={handleNext} />
        </div>
      </Card>
    </div>
  );
};

export default KycStepOne;
