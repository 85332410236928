import { Get, Patch, Post, Put } from "../fetchMethods";
import { appConst } from "../../constants/texts";
import { convertBase64ToFile, toFormUrlEncoded } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export const BASE_URL = appConst.BASE_URL;
console.log({ BASE_URL });

const Fetch = {
  // Authentication
  // done
  uploadImageApi(payload) {
    let file;
    const formData = new FormData();
    let url = BASE_URL + "userkyc/upload";
    let { photo, type } = { ...payload };
    if (type == "base64") {
      file = convertBase64ToFile(photo, "a.png", type);
      formData.append("photo", file);
    } else {
      formData.append("photo", photo);
    }

    return Post(formData, url);
  },
  getImageApi(payload) {
    let url = BASE_URL + "userkyc/getimg";
    let formBody = toFormUrlEncoded({ id: payload });

    return Post(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },
  getDocTypeApi() {
    let url = BASE_URL + "userkyc/doclist";

    return Get(url);
  },
  getCountriesApi() {
    let url = `https://countriesnow.space/api/v0.1/countries/positions`;
    return Get(url);
  },
  sendIdApi(payload) {
    let url = BASE_URL + "userkyc/initiate_kyc";
    const formBody = toFormUrlEncoded({ userId: payload });
    return Post(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },

  updateIdApi(payload) {
    let url = BASE_URL + "admin/v1/user/update-kyc-id";
    const formBody = toFormUrlEncoded(payload);
    return Put(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },

  sendDocDataApi(payload, callback) {
    let url = BASE_URL + "userkyc/docupload";
    const formBody = toFormUrlEncoded(payload);
    return Post(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },

  sendDocDataApiSelfi(payload) {
    let url = BASE_URL + "userkyc/selfieUpload";
    const formBody = toFormUrlEncoded(payload);
    return Post(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },

  getKYCStatusApi(payload) {
    let url = BASE_URL + "userkyc/get_kyc_data";
    const formBody = toFormUrlEncoded({ kycId: payload });
    return Post(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },

  checkLivenessApi(payload) {
    let file;
    const formData = new FormData();
    let url = BASE_URL + "kyc/check-liveness/";
    let { photo, type } = { ...payload };
    if (type == "base64") {
      file = convertBase64ToFile(photo, "a.png", type);
      // console.log(file,'fileeeeee');
      formData.append("selfie", JSON.stringify(photo.split(",")[1]));
    }

    return Post(formData, url, {
      Accept: "*/*",
    });
  },

  checkEmailApi(payload) {
    let url = BASE_URL + "admin/v1/client/" + payload;
    const formBody = toFormUrlEncoded({ id: payload });
    return Get(url);
  },
  verifyDetails(payload, id) {
    let url = BASE_URL + "admin/v1/client/" + id;
    const formBody = toFormUrlEncoded(payload);
    return Patch(formBody, url, {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    });
  },
  getSurveyData(id) {
    let url =
      BASE_URL +
      "admin/v1/survey/all-question/e2b7d3c9-3edd-456f-80b7-cf0ec0b30c5a";
    return Get(url);
  },
  sendSurveyDataApi(payload) {
    console.log(payload, "fgfgfgfgf");

    let url =
      BASE_URL + "admin/v1/survey/sumbit/e2b7d3c9-3edd-456f-80b7-cf0ec0b30c5a";
    // const formBody = toFormUrlEncoded(payload);
    return Post(payload, url, {
     
      Accept: "application/json",
    });
  },
};

export default Fetch;
