import React, { Fragment, useEffect } from 'react'
import './RadioButton.css'
import { useSelector } from 'react-redux'
const RadioButton = (props) => {
  const {
    id,
    title = '',
    checked=false,
    index, 
    customLabelCls,
    isDisabled=false,
    handleChange = () => { }
  } = { ...props }
  const state =useSelector(state=>state.common);
  const {
    imgType,
    showClssName
  }={...state};
  useEffect(() => { 
    if(imgType){
      if(imgType==index+1){
        handleChange(imgType-1);
      }
    }
  }, [])
  return (
    <Fragment>
      <input 
        type="radio" 
        name={`doc-radios-${index}`}
        id={`doc-radios-${index}`}
        checked={checked}
        className={imgType == null && showClssName ?  'error-radio' : null}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <label
        className={`radio-title ${customLabelCls}`}
        htmlFor={`doc-radios-${index}`}
      >{title}</label>
    </Fragment>
  )
}

export default RadioButton