import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import KycModal from "../../Common Modal/kycModal";
import { Button } from "../../elements";
import logo from "../../logo.svg";
import {
  checkEmailAction,
  verificationAction,
} from "../../redux/actions/Actions";
import LinkExpireError from "../../ui/LinkExpireError";
import MobileInput from "../../ui/MobileInput/MobileInput";
import "./ClientSide.scss";

const ClientSide = () => {
  const [show, setShow] = useState(false);
  const [loaderKyc, setLoaderKyc] = useState(false);
  const [phn, setPhn] = useState({ err: "", value: "", code: "", no: "" });
  const params = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { detail: emaildata, loading } = { ...state };
  const [altEmail, setAltEmail] = useState({
    err: "",
    value: emaildata?.data?.altEmail ? emaildata?.data?.altEmail : "",
  });
  const [altPhn, setAltPhn] = useState({
    err: "",
    value: emaildata?.data?.altPhone ? emaildata?.data?.altPhone : "",
  });

  useEffect(() => {
    if (params?.id) {
      dispatch(checkEmailAction(params?.id));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phn?.err && !altEmail?.err && !altPhn?.err) {
      setLoaderKyc(true);
      let data = {
        firstName: emaildata?.data?.firstName,
        lastName: emaildata?.data?.lastName,
        email: emaildata?.data?.email,
        phone: emaildata?.data?.phone
          ? emaildata?.data?.phone
          : phn?.no && phn?.code + "-" + phn?.no,
        altPhone: altPhn?.no && altPhn?.code + "-" + altPhn?.no,
        altEmail: altEmail?.value,
        projectName: emaildata?.data?.projectName,
      };
      const res = await dispatch(verificationAction({ data, id: params?.id }));
      await new Promise((resolve) => setTimeout(resolve, 3000));
      console.log({ data, res });
      if (res) {
        setLoaderKyc(false);
        setShow(true);
      }
      setLoaderKyc(false);
    }
  };

  const LoadingData = () => {
    return (
      <div
        className="clientSide_formcard d-flex flex-column justify-content-center align-items-center "
        style={{ height: "60vh" }}>
        <img src={logo} alt="logo" style={{ marginBottom: "40px" }} />
        <Spinner style={{ minWidth: "100px", minHeight: "100px" }} />
      </div>
    );
  };

  return (
    <>
      <div className="clientSide">
        {loading ? (
          <LoadingData />
        ) : (
          <>
            {emaildata?.data?.VerificationStatus === "NOT_APPLIED" ||
            emaildata?.data?.VerificationStatus === "DECLINED" ||
            emaildata?.data?.VerificationStatus === "PENDING" ? (
              <>
                {(emaildata?.data?.VerificationStatus === "NOT_APPLIED" ||
                  emaildata?.data?.VerificationStatus === "DECLINED") && (
                  <Form
                    className="clientSide_formcard"
                    onSubmit={(e) => handleSubmit(e)}>
                    <h2>Review Your Details</h2>
                    <Row className="input_type">
                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <Form.Label className="mb-2">First Name</Form.Label>
                        <Form.Control
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeholder="Please enter first name"
                          value={emaildata?.data?.firstName}
                          readOnly
                        />
                      </Col>
                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <Form.Label className="mb-2"> Last Name</Form.Label>
                        <Form.Control
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeholder="Please enter last name"
                          value={emaildata?.data?.lastName}
                          readOnly
                        />
                      </Col>
                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <Form.Label className="mb-2">Project Name</Form.Label>
                        <Form.Control
                          id="projectName"
                          name="projectName"
                          type="text"
                          placeholder="Please enter project name"
                          value={emaildata.data?.projectName}
                          readOnly
                        />
                      </Col>
                      <Col xs={12} sm={6} className=""></Col>
                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <Form.Label className="mb-2">
                          Client Email ID
                        </Form.Label>
                        <Form.Control
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Your Email ID"
                          value={emaildata?.data?.email}
                          readOnly
                        />
                      </Col>
                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <Form.Label className="mb-2">
                          Alternate Email ID
                        </Form.Label>
                        <Form.Control
                          id="altEmail"
                          name="altEmail"
                          type="email"
                          placeholder="Your Email ID"
                          onChange={(e) => {
                            if (e.target.value === emaildata?.data?.email) {
                              setAltEmail({
                                err: "Both emails are not same",
                                value: e.target.value,
                              });
                            } else {
                              setAltEmail({ err: "", value: e.target.value });
                            }
                          }}
                          value={altEmail?.value}
                        />
                        {altEmail?.err && (
                          <div>
                            <p style={{ color: "red" }}>{altEmail?.err}</p>
                          </div>
                        )}
                      </Col>

                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <MobileInput
                          id="phone"
                          name="phone"
                          value={
                            emaildata?.data?.phone
                              ? emaildata?.data?.phone
                              : phn?.value
                          }
                          specialLabel={" Phone Number"}
                          onChange={(phone, countryObj, e, formattedValue) => {
                            let rawNumber = phone.slice(
                              countryObj.dialCode.length
                            );
                            console.log({
                              formattedValue,
                              phone,
                              countryObj,
                              rawNumber,
                            });

                            if (rawNumber && rawNumber?.length < 5) {
                              setPhn({
                                err: "Phone no must be longer than or equal to 5 digits ",
                                value: formattedValue,
                                code: "",
                              });
                            } else if (phone?.length > 18) {
                              setPhn({
                                err: "Phone no. max length exceed",
                                value: formattedValue,
                                code: "",
                              });
                            } else if (
                              formattedValue.replace(/\s+/, "-") ===
                              altPhn?.value
                            ) {
                              setPhn({
                                err: "Both phone no are not same",
                                value: formattedValue,
                                code: "",
                              });
                            } else {
                              setPhn({
                                err: "",
                                value: formattedValue?.replace(/\s+/, "-"),
                                code: formattedValue && countryObj?.dialCode,
                                no: rawNumber,
                              });
                            }
                          }}
                        />
                        {phn?.err && (
                          <div>
                            <p style={{ color: "red" }}>{phn?.err}</p>
                          </div>
                        )}
                      </Col>
                      <Col xs={12} sm={6} className="input_type_detail mb-4">
                        <MobileInput
                          id="altPhone"
                          name="altPhone"
                          value={altPhn?.value}
                          onChange={(phone, countryObj, e, formattedValue) => {
                            let rawNumber = phone.slice(
                              countryObj.dialCode.length
                            );
                            console.log(
                              phone,
                              emaildata?.data?.phone,
                              countryObj?.format,
                              rawNumber?.length,
                              phn?.value
                            );
                            if (rawNumber && rawNumber?.length < 5) {
                              setAltPhn({
                                err: "Phone no must be longer than or equal to 5 digits ",
                                value: formattedValue,
                                code: "",
                              });
                            } else if (
                              phone === emaildata?.data?.phone ||
                              formattedValue.replace(/\s+/, "-") === phn?.value
                            ) {
                              setAltPhn({
                                err: "Both phone no are not same",
                                value: formattedValue,
                                code: "",
                              });
                            } else {
                              setAltPhn({
                                err: "",
                                value: formattedValue.replace(/\s+/, "-"),
                                code: formattedValue && countryObj?.dialCode,
                                no: rawNumber,
                              });
                            }
                          }}
                          specialLabel={"Alternate Phone Number"}
                          //  isValid={(value, country) =>  setAltPhn({ err: "", value: "" ,code:value})}
                        />
                        {altPhn?.err && (
                          <div>
                            <p style={{ color: "red" }}>{altPhn?.err}</p>
                          </div>
                        )}
                      </Col>
                      <Col xs={12} sm={6}>
                        <div className="btn_row mt-2">
                          <Button
                            title="Verify and Submit"
                            type="submit"
                            className="w-100"
                            loader={loaderKyc}
                            isDisabled={altEmail?.err || altPhn?.err}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
                {emaildata?.data?.VerificationStatus === "PENDING" && (
                  <div
                    className="clientSide_formcard d-flex flex-column justify-content-center align-items-center "
                    style={{ height: "60vh" }}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ marginBottom: "40px" }}
                    />
                    <h2 className="text-center">
                      Thanks for submitting the KYC.
                    </h2>
                  </div>
                )}
              </>
            ) : (
              <>{!loaderKyc ? <LinkExpireError /> : <LoadingData />}</>
            )}
          </>
        )}
      </div>
      {show && <KycModal show={show} onHide={() => setShow(false)} />}
    </>
  );
};

export default ClientSide;
