import createSagaMiddleware from "@redux-saga/core";
import { createStore, applyMiddleware } from "redux";
import rootReducers from "./rootReducers";
import { mySaga } from "./saga/index";


const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
    rootReducers,
   applyMiddleware(sagaMiddleware)
); 

sagaMiddleware.run(mySaga);