export const detectButtonClick = () => {
  document.addEventListener("click", (e) => {
    if (e.target.nodeName == "BUTTON") {
      //   setStartLoad(true);
    }
  });
};

export const convertBase64ToFile = (dataurl, filename, mimeType) => {
  switch (mimeType) {
    case "base64": {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    case "blob": {
      return new File([dataurl], filename);
    }
    default:
      break;
  }
};
export const toFormUrlEncoded = (details) => {
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return formBody;
};

export const toFormData = (details) => {
  var formData = new FormData();

  for (var property in details) {
    // formData.append([property])
  }

  return formData;
};

export const fileSizeCheck = (size) => {
  // size incoming is in bytes
  if (5 * 1024 * 1024 >= size && 10240 < size) {
    return true;
  }  return false;
};

export const stopCamera = async () => {
  return await navigator.mediaDevices
    .getUserMedia({
      video: true,
    })
    .then(function (stream) {
      if (stream) {
        if (stream.getVideoTracks && stream.getAudioTracks) {
          stream.getVideoTracks().map(function (track) {
            stream.removeTrack(track);
            track.stop();
          });
          stream.getAudioTracks().map(function (track) {
            stream.removeTrack(track);
            track.stop();
          });
        } else {
          stream.stop();
        }
      } else {
        stream.stop();
      }
    });
};
