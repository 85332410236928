import {
  UPLOAD_IMAGE,
  GET_IMAGE,
  SET_IMAGES,
  SET_IMAGE_TYPE,
  SET_IMAGE_SIDE,
  STOP_LOADING,
  SHOW_CLASS_NAME,
  GET_DOC_TYPE,
  GET_COUNTRIES,
  START_LOADING,
  SET_KYC_STEP,
  SET_COUNTRIES,
  SEND_ID,
  SET_KYC_ID,
  SEND_KYC_DOCS,
  SET_DOC_TYPE,
  SET_UPLOADED_IMAGES_ID,
  GET_KYC_STATUS,
  SEND_KYC_DOCS_SELFI,
  SELECTED_COUNTRY,
  CHECK_LIVENESS,
  SELECTED_DOC_DETAILS,
  CHECK_EMAIL,
  VERIFICATION,
  GET_SURVEY_DATA,
  SET_SURVEY_DATA,
  SEND_SURVEY_DATA,
} from "../Types/actionTypes";
import {
  SET_EMAIL,
  SET_UPDATED_KYC_ID,
  UPDATE_ID,
  // SET_SURVEY_DATA,
} from "../actionTypes/actionTypes";
export const setShowClassName = (payload) => {
  return {
    type: SHOW_CLASS_NAME,
    payload,
  };
};
export const startLoading = () => {
  return {
    type: START_LOADING,
  };
};
export const stopLoading = () => {
  return {
    type: STOP_LOADING,
  };
};
export const uploadImage = (newData) => {
  return {
    type: UPLOAD_IMAGE,
    payload: newData,
  };
};
export const getImage = (newData) => {
  return {
    type: GET_IMAGE,
    payload: newData,
  };
};

export const setImages = (newData) => {
  return {
    type: SET_IMAGES,
    payload: newData,
  };
};
export const setImageType = (newData) => {
  return {
    type: SET_IMAGE_TYPE,
    payload: newData,
  };
};
export const setImageSide = (newData) => {
  return {
    type: SET_IMAGE_SIDE,
    payload: newData,
  };
};

export const getDocTypes = () => {
  return {
    type: GET_DOC_TYPE,
  };
};

export const setDocTypes = (newData) => {
  return {
    type: SET_DOC_TYPE,
    payload: newData,
  };
};

export const getCountries = () => {
  return {
    type: GET_COUNTRIES,
  };
};
export const setCountries = (newData) => {
  return {
    type: SET_COUNTRIES,
    payload: newData,
  };
};

export const sendId = (newData) => {
  return {
    type: SEND_ID,
    payload: newData,
  };
};
export const updateId = (newData) => {
  return {
    type: UPDATE_ID,
    payload: newData,
  };
};

export const sendKycDocs = (newData, callback) => {
  return {
    type: SEND_KYC_DOCS,
    payload: newData,
    callback,
  };
};

export const sendKycDocsSelfi = (newDataSelfi, callback) => {
  return {
    type: SEND_KYC_DOCS_SELFI,
    payload: newDataSelfi,
    callback,
  };
};

export const setKYCID = (newData) => {
  console.log({ newData });
  return {
    type: SET_KYC_ID,
    payload: newData,
  };
};
// export const setUpdatedKYCID = (newData) => {
//     console.log({newData})
//     return {
//         type: SET_UPDATED_KYC_ID,
//         payload: newData
//     }
// }

export const setUploadedImagesId = (newData) => {
  return {
    type: SET_UPLOADED_IMAGES_ID,
    payload: newData,
  };
};
export const getKYCStatus = (newData) => {
  return {
    type: GET_KYC_STATUS,
    payload: newData,
  };
};

export const setKYCStep = (newData) => {
  return {
    type: SET_KYC_STEP,
    payload: newData,
  };
};

export const selectedCountryAction = (newData) => {
  return {
    type: SELECTED_COUNTRY,
    payload: newData,
  };
};

export const checkLivenessAction = (newData) => {
  return {
    type: CHECK_LIVENESS,
    payload: newData,
  };
};

export const setSelectedDocDetailsAction = (newData) => {
  return {
    type: SELECTED_DOC_DETAILS,
    payload: newData,
  };
};

export const checkEmailAction = (id) => {
  // console.log({data})
  return {
    type: CHECK_EMAIL,
    payload: id,
  };
};
export const setEmail = (data) => {
  console.log({ data });
  return {
    type: SET_EMAIL,
    payload: data,
  };
};
export const verificationAction = (data) => {
  return {
    type: VERIFICATION,
    payload: data,
  };
};
export const getSurveyData = (id) => {
  return {
    type: GET_SURVEY_DATA,
    // payload:id
  };
};
export const setSurveyData = (data) => {
  console.log(data, "fffffddddd");
  return {
    type: SET_SURVEY_DATA,
    payload: data,
  };
};
export const sendSurvey = (data) => {
  console.log(data, "fffffddddd");
  return {
    type: SEND_SURVEY_DATA,
    payload: data,
  };
};
