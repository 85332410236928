import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setImageSide,
  setImageType,
  setImages,
  setShowClassName,
  uploadImage,
} from "../../redux/actions/Actions";
import { fileSizeCheck } from "../helpers";
import { toast } from "react-toastify";
const UseImageUpload = (props) => {
  const { initialValue } = { ...props };
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  let { uploadedImages } = state;
  const [uploadedImage, _setUploadedImage] = useState(
    initialValue || { front: uploadedImages.front, back: uploadedImages.back }
  );
  const [showInputFront, setShowInputFront] = useState(true);
  const [showInputBack, setShowInputBack] = useState(true);
  const setUploadedImage = (e, type, fromStore = false) => {
    if (e) {
      const fileType =
        (uploadedImages?.front && type == "front") ||
        (uploadedImages?.back && type == "back")
          ? "image"
          : e.target.files[0]?.type?.split("/")[0];
      if (fileType == "image") {
        if (fileSizeCheck(e.target.files[0].size)) {
          let localFile = fromStore
            ? e
            : URL.createObjectURL(e.target.files[0]); // this is for local img tag to show uploaded files
          if (type == "front") {
            _setUploadedImage({
              ...uploadedImage,
              front: localFile,
            });
            if (fromStore == false) {
              let file = e.target.files[0];
              dispatch(setImageSide("front"));
              dispatch(uploadImage({ body: { photo: file, type: "blob" } }));
            }
          } else {
            _setUploadedImage({
              ...uploadedImage,
              back: localFile,
            });
            if (fromStore == false) {
              let file = e.target.files[0];
              dispatch(setImageSide("back"));
              dispatch(uploadImage({ body: { photo: file, type: "blob" } }));
            }
          }
        } else {
          toast.error("File size must be in between 10KB to 5MB.");
        }
      } else {
        toast.error("Allowed file types are .jpg, .png, .jpeg, .webp.");
      }
    } else {
      if (type == "front") {
        _setUploadedImage({
          ...uploadedImage,
          front: null,
        });
        dispatch(setImageSide("front"));
        dispatch(setImages(null));
      } else {
        _setUploadedImage({
          ...uploadedImage,
          back: null,
        });
        dispatch(setImageSide("back"));
        dispatch(setImages(null));
      }
    }
  };
  useEffect(() => {
    if (uploadedImage.front) {
      setShowInputFront(false);
      // setShowInputBack(true)
    } else {
      setTimeout(() => {
        setShowInputFront(true);
      }, 500);
    }
  }, [uploadedImage.front]);
  useEffect(() => {
    if (uploadedImage.back) {
      setShowInputBack(false);
    } else {
      setTimeout(() => {
        setShowInputBack(true);
      }, 500);
    }
  }, [uploadedImage.back]);

  return {
    values: {
      front: uploadedImage.front,
      back: uploadedImage.back,
      showInputBack,
      showInputFront,
    },
    setUploadedImage,
  };
};

export default UseImageUpload;
