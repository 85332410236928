export const Post = (body, newurl, header) => {
  const internetStatus = true;
  const url = newurl;
  const data = {
    method: "POST",
    headers: header
      ? header
      : {
          Accept: "application/json",
        },
    body,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status === 404) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 7,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          }
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      })
      .catch((error) => {
        if (internetStatus == true) {
          return reject({
            result: "Something went wrong.",
            status: 0,
          });
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      });
  });
};

export const Get = (newurl, body, header) => {
  const internetStatus = true;
  const url = newurl;
  const data = {
    method: "GET",
    headers: header
      ? header
      : {
          Accept: "application/json",
        },
    body,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          }
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      })
      .catch((error) => {
        if (internetStatus == true) {
          return reject({
            result: "Something went wrong.",
            status: 0,
          });
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      });
  });
};

export const Patch = (body, newurl, header) => {
  const internetStatus = true;
  const url = newurl;
  const data = {
    method: "PATCH",
    headers: header
      ? header
      : {
          Accept: "application/json",
        },
    body,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status === 404) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 7,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          }
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      })
      .catch((error) => {
        if (internetStatus == true) {
          return reject({
            result: "Something went wrong.",
            status: 0,
          });
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      });
  });
};

export const Put = (body, newurl, header) => {
  const internetStatus = true;
  const url = newurl;
  const data = {
    method: "PUT",
    headers: header
      ? header
      : {
          Accept: "application/json",
        },
    body,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          } else if (responseData.status === 404) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 7,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: "Something went wrong.",
                });
              }
            });
          }
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      })
      .catch((error) => {
        if (internetStatus == true) {
          return reject({
            result: "Something went wrong.",
            status: 0,
          });
        } else {
          return reject({
            result: "Please check your internet connection.",
            status: 0,
          });
        }
      });
  });
};
