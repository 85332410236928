import React from "react";
import "./DocUpload.css";
import { UnorderedList } from "../../../../../elements";
import ImageUpload from "../imageUpload/ImageUpload";
import {
  appConst,
  docUploadImages,
  photoConditions,
} from "../../../../../constants/texts";
import { useSelector } from "react-redux";
const DocUpload = (props) => {
  const state = useSelector((state) => state.common);
  const { imgType } = { ...state };
  console.log({state})
  return (
    <div className="choose-doc-body">
      {/* title */}
      <p>{appConst[`header${imgType}`]}</p>
      {/* photo condition */}
      <UnorderedList list={photoConditions} />
      {/* dummy images */}
      <div className="img-guide">
        {docUploadImages.img1.map((item) => {
          return <img src={item} alt="img" />;
        })}
      </div>

      {/* upload image section */}
      <ImageUpload />
    </div>
  );
};

export default DocUpload;
